import React from "react";
import { Box, Grid } from "@mui/material";
import TextEditor from "../../inputs/TextEditor";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import parse from "html-react-parser";

export default function AboutProjectOwner({
  owner,
  project,
  editProjectOwner,
  aboutOwner,
  handleAboutOwnerChange,
  handleEditProjectOwner,
  closeOwnerEditor,
  updateAboutOwner,
}) {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={owner ? 10 : 12}>
          {!editProjectOwner ? (
            <Box>
              <Box
                sx={{
                  img: { inlineSize: "-webkit-fill-available" },
                  fontFamily: "Manrope",
                  lineHeight: "24px",
                  color: "#6B7280",
                  letterSpacing: "0.01em",
                }}
              >
                {parse(`${project.owner.about}`)}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                borderRadius: "10px",
                minHeight: { xs: "250px", sm: "300px" },
              }}
            >
              <TextEditor
                value={aboutOwner}
                setValue={handleAboutOwnerChange}
              />
            </Box>
          )}
        </Grid>
        {owner && (
          <Grid
            item
            xs={2}
            md={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: { xs: "1rem", md: "0" },
            }}
          >
            <Box>
              <EditIcon
                onClick={handleEditProjectOwner}
                sx={{
                  fontSize: "20px",
                  color: "#6B7280",
                  mt: "auto",
                  mb: "auto",
                  cursor: "pointer",
                }}
              />
            </Box>

            {editProjectOwner ? (
              <Box
                sx={{
                  display: "flex",
                  pl: "6px",
                }}
              >
                <CheckCircleOutlineIcon
                  onClick={updateAboutOwner}
                  sx={{
                    fontSize: "24px",
                    color: "green",
                    cursor: "pointer",
                  }}
                />
                <HighlightOffIcon
                  onClick={closeOwnerEditor}
                  sx={{
                    fontSize: "24px",
                    color: "red",
                    cursor: "pointer",
                    pl: "5px",
                  }}
                />
              </Box>
            ) : (
              ""
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
