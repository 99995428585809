import React from "react";
import styled from "styled-components";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CheckIcon from "@mui/icons-material/Check";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Box } from "@mui/material";

const StyledSelect = styled(Select)`
  width: auto;
  .MuiSelect-select {
    padding: 9px 13px;
    color: #111827;
    font-size: 14px;
    font-family: "Manrope";
    font-weight: 400;
    width: 100%;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-weight: 200;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  color: #374151;
  font-family: "Manrope";
  letter-spacing: 0.01em;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &.Mui-selected {
    color: #111827;
    background: #fff;
    font-weight: 600;
  }
  &.Mui-selected:hover {
    color: #111827;
    background: #fff;
    font-weight: 600;
  }
`;

const DropDown = ({ value, handleChange, items, error }) => {
  return (
    <StyledSelect
      value={value}
      onChange={handleChange}
      IconComponent={UnfoldMoreIcon}
      renderValue={(selected) => selected}
      fullWidth
      error={error}
      sx={{
        fieldset: {
          border: "1px solid",
          borderColor: "#D1D5DB",
          borderRadius: "6px",
        },
      }}
    >
      {items.map((item) => (
        <StyledMenuItem key={item.name} value={item.name}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {item.name}
            {item.name === value && (
              <CheckIcon sx={{ fill: "#0B5394", fontSize: "20px" }} />
            )}
          </Box>
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
};

export default DropDown;
